import { React, useEffect } from "react";
import "./style.css";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="relative w-full h-full py-[150px] laptop:py-[200px] content-page bg-contentPageBg">
      <div className="relative max-w-[1140px] mx-auto px-6">
        <h1 className="font-medium tracking-tighter laptop:-tracking-[1px] text-white text-center">
          Terms and Conditions
        </h1>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using the services provided by <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> ("we," "us," or "our"), including any content, functionality, and services offered on or through our website, you, as the user, agree to be bound by these Terms of Service ("Terms") and any additional terms and conditions that are referenced herein or that otherwise may apply to specific sections of the services. If you do not agree to all these Terms, do not use our services.</p>
        <h2>2. Services Provided</h2>
        <p><a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> offers a platform for decentralized artificial intelligence applications designed to facilitate user interaction with AI tools and services. We reserve the right to modify, suspend, or discontinue the services, or any part thereof, at any time without prior notice. All new features or tools shall also be subject to these Terms.</p>
        <h2>3. User Accounts</h2>
        <p>To access certain features of our services, users may be required to create an account. You agree to provide accurate, current, and complete information during registration and promptly update this information to maintain its accuracy. You are responsible for maintaining the security of your account credentials and for all activities under your account. <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> reserves the right, at its absolute discretion, to refuse registration or cancel any account that it believes violates these Terms.</p>
        <h2>4. User Responsibilities</h2>
        <p>By using our services, you agree to:</p>
        <ul>
          <li>Use the services only for lawful purposes and in accordance with these Terms. </li>
          <span className="mt-16"></span>
          <li>Refrain from transmitting any unsolicited or unauthorized advertising, promotional materials, or similar communications, such as junk mail, chain letters, or spam.        </li>
          <span className="mt-16"></span>
          <li>Avoid impersonation of <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a>, its employees, other users, or any other individuals or entities.</li>
          <span className="mt-16"></span>
          <li> Abstain from engaging in activities that could damage or impair the operation or security of the services or interfere with others’ use and enjoyment of the services.
          </li>
          <span className="mt-16"></span>
        </ul>
        <h2>5. Intellectual Property Rights</h2>
        <p>All content, features, and functionality available through our services, including text, graphics, logos, icons, images, and software, are the exclusive property of <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> or its licensors and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. Users are not permitted to modify, reproduce, distribute, create derivative works from, or otherwise exploit for any commercial purposes any part of the services without explicit written permission from <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a>.</p>
        <h2>6. Third-Party Links</h2>
        <p>Our services may contain links to third-party websites or services not owned or controlled by <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a>. We assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. Users acknowledge that <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> shall not be liable for any damage or loss caused by or in connection with their use of any third-party services.</p>
        <h2>7. Limitation of Liability</h2>
        <p>To the maximum extent permitted by applicable law, <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a> and its affiliates, licensors, service providers, employees, agents, officers, or directors shall not be liable for any incidental, indirect, consequential, special, exemplary, or punitive damages arising from or related to your use of, or inability to use, the services, any conduct or content of any third party on the services, or any unauthorized access or use of your content, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not we have been informed of the possibility of such damage.</p>
        <h2>8. Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless <a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a>, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, service providers, suppliers, successors, and assigns from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from your violation of these Terms or your use of our services, including but not limited to any use of the content, services, and products other than as expressly authorized in these Terms.</p>
        <h2>9. Governing Law</h2>
        <p>These Terms and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with them or their subject matter or formation shall be governed by and construed in accordance with the laws of the Cayman Islands, without regard to its conflict of law principles. Any legal proceedings arising out of or related to these Terms shall be instituted exclusively in the courts of the Cayman Islands. M Innovation Ltd is a registered Cayman entity using the Inflectiv brand name.</p>
        <h2>10. Changes to These Terms</h2>
        <p>We may revise and update these Terms at our sole discretion. All changes are effective immediately when posted and apply to all access to and use of the services thereafter. Your continued use of the services following the posting of revised Terms means you accept and agree to the changes.</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
