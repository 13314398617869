import React, { useEffect, useState } from "react";
import { Button, Container, SignupModal } from "components";
import {
  ARROW_ICONS,
  INFECTIVlOGOS,
  SOCIAL_ICONS_HEADER,
  SOCIAL_ICONS_HEADER_NEW,
} from "utils/constants";
import { motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";

const navigationLinks = [
  // ["Home", "/"],
  ["Developer", "/developers"],
  // ["Node Sale", "/buy-nodes"],
  // ["TapNode", `${process.env.REACT_APP_TAPNODE_URL}`],
];

const variants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: "-100%",
  },
};

function Header() {
  const [viewSideBar, setViewSideBar] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (viewSideBar) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const bodyElement = document.body;
  isShowWhiteListModal
    ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <header className="fixed top-[10px] w-full z-[99] ease-linear duration-300">
        <Container className="!px-2 xs:!px-4">
          <nav className="bg-chineseBlack/50 backdrop-blur-[15px] rounded-lg lg:rounded-2xl laptop:rounded-3xl py-3 px-[10px] md:py-3 md:px-[15px] laptop:py-[21px] laptop:px-[30px]">
            <div className="navbar-nav flex items-center justify-between gap-1 md:gap-4">
              <div className="flex items-center gap-[6px] md:gap-2">
                {/* <div
                  className="w-[22px] h-[22px] md:w-10 md:h-10 flex lg:hidden items-center justify-center cursor-pointer *:w-[100%] *:h-[100%]"
                  onClick={() => setViewSideBar(!viewSideBar)}
                >
                  {GENERAL_ICONS.menu}
                </div> */}

                <Link
                  className={`relative flex items-center *:h-[30px] *:md:h-[42px] *:w-[110px] *:xs:w-[120px] *:md:w-[193px]
                  z-20 ${scroll && "!border-black/20"}`}
                  to="/"
                  onClick={() => scrollToTop()}
                >
                  {INFECTIVlOGOS.header}
                </Link>
              </div>

              {/* <div className="hidden lg:flex items-center justify-between w-full lg:w-auto absolute left-2/4 -translate-x-2/4">
                <ul className="w-full flex flex-col justify-center mt-4 lg:flex-row lg:mt-0 z-10 space-x-7 laptop:space-x-12">
                  {navigationLinks.map(([title, url]) => (
                    <li
                      key={title}
                      className="nav-link text-sm/5 font-medium -tracking-07 capitalize"
                    >
                      <NavLink
                        to={`${url}`}
                        className={({ isActive }) =>
                          `hover:text-lightOrange ${
                            isActive
                              ? "text-lightOrange font-semibold"
                              : "text-white/80"
                          }`
                        }
                        id="listId"
                      >
                        {title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div> */}

              <div className="relative flex items-center gap-3">
                <ul className="hidden md:flex gap-3">
                {SOCIAL_ICONS_HEADER_NEW.map((icon) => (
                    <li
                        className="flex items-center justify-center"
                        key={icon.name}
                    >
                        <a
                            href={icon.url}
                            className="w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-dim/15 text-white hover:bg-black hover:text-white p-[10px] duration-300"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {icon.svg}
                        </a>
                    </li>
                ))}
                  {/* <li className="flex items-center justify-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/inflectivAI"
                      className={`w-10 h-10 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-dim/15 text-white hover:bg-black hover:text-white p-[10px] duration-300`}
                    >
                      {SOCIAL_ICONS_HEADER.twitter}
                    </a>
                  </li> */}
                </ul>

                <Button
                  className={`light-button py-2 px-2 lg:py-[11px] lg:px-6 !text-xs md:!text-sm`}
                  onClick={() => setShowWhiteListModal(true)}
                >
                  <span className="mr-[6px]">Sign up for Whitelist</span>
                  <span
                    className={`w-4 h-4 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                  >
                    {ARROW_ICONS.circleArrow}
                  </span>
                </Button>
              </div>
            </div>
          </nav>
        </Container>
      </header>

      <motion.div
        animate={viewSideBar ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5 }}
        className={`sidebar hidden fixed top-0 left-0 h-screen w-full laptop:w-[60vw] xl:w-[50vw] bg-white laptop:rounded-r-[40px] text-black border-2 border-gray/15 z-[999] ${
          !viewSideBar && "hidden"
        }`}
      >
        <div className="relative p-4 md:py-5 md:px-8 w-full h-full">
          <div
            className="absolute top-4 left-4 laptop:top-[44px] laptop:left-[70px] w-7 h-7 rounded-full bg-lightGray/[.08] flex items-center justify-center p-1 cursor-pointer z-10"
            onClick={() => setViewSideBar(false)}
          >
            <svg
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75195 5.83325L23.0842 22.1655"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.7498 22.1655L23.082 5.83325"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="relative w-full h-full py-12 pb-0 z-[5]">
            <div className="relative w-full h-full flex flex-col gap-6">
              <ul className="flex flex-col justify-start items-start space-y-4 lg:space-y-10 overflow-x-auto">
                {navigationLinks.map(([title, url], index) => (
                  <li
                    key={title}
                    className="nav-link text-[28px] lg:text-[34px] laptop:text-[56px] font-bold capitalize w-full"
                  >
                    <NavLink
                      to={`${url}`}
                      className={({ isActive }) =>
                        `flex items-center justify-between gap-3 w-full ${
                          isActive ? "text-lightOrange" : "text-black/80"
                        }`
                      }
                      id="listId"
                      onClick={() => setViewSideBar(false)}
                    >
                      <div className="flex items-center">
                        {/* <span className="text-base md:text-[24px] font-normal mr-4 lg:mr-10">
                          {index + 1}.
                        </span> */}
                        {title}
                      </div>
                      <span className="w-5 h-5 md:w-[36px] md:h-[36px] *:w-full *:h-full">
                        {ARROW_ICONS.arrowIcon}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>

              <div className="w-full h-auto flex items-start md:items-center justify-between flex-col md:flex-row gap-4 mt-auto">
                <div className="flex items-center">
                  <div className="max-w-12 w-full *:w-full *:h-full">
                    {INFECTIVlOGOS.colorStrokeLogo}
                  </div>
                  <ul className="flex gap-3 ml-3 pl-3 border-l-2 border-black/20">
                    <li className="flex items-center justify-center">
                      <a
                        href="/#/"
                        className="w-8 h-8 laptop:w-[48px] laptop:h-[48px] flex items-center justify-center rounded-full border-[1.5px] border-black/20 text-black hover:bg-black hover:text-white duration-300 *:w-[50%] *:h-[50%]"
                      >
                        {SOCIAL_ICONS_HEADER.twitter}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="flex items-center gap-4 md:gap-[30px] text-[13px]/5">
                  <a
                    href="/#/"
                    className="flex items-center text-black hover:underline hover:text-black/75 duration-300"
                  >
                    Privacy Policy
                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full ml-2">
                      {ARROW_ICONS.externalLink}
                    </span>
                  </a>
                  <a
                    href="/#/"
                    className="flex items-center text-black hover:underline hover:text-black/75 duration-300"
                  >
                    Terms & Conditions
                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full ml-2">
                      {ARROW_ICONS.externalLink}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}

export default Header;
