import React, { useRef, useEffect } from "react";
import {
  Container,
  SectionDetail,
  SectionTitle,
  VideoPlayer,
} from "components";

function AiApp() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);
  return (
    <section className="relative pb-[90px] pt-10 lg:pb-[180px] lg:pt-[80px] bg-black overflow-hidden gradient-cirlce before:bottom-[-5%] before:-left-[5%] before:rotate-[-111deg]">
      <Container className="relative max-w-inner z-10">
        <div className="text-center max-w-[90%] lg:max-w-[75%] laptop:max-w-[900px] mx-auto pb-20">
          <SectionTitle
            className="mb-5 lg:mb-8"
          >
            Powering Next Generation AI Apps
          </SectionTitle>
          <SectionDetail>
            With Inflectiv, AI dApps and agents access fine-tuned datasets to excel across every sector. From precise financial forecasting to personalized e-commerce agents and groundbreaking healthcare insights, Inflectiv empowers AI with unmatched innovation and scalability.
          </SectionDetail>
        </div>
        <div className="grid laptop:grid-cols-2 gap-12 md:gap-20 laptop:gap-8 items-center">
          <div className="col-span-1">
            <div className="max-w-[90%] lg:max-w-[75%] laptop:max-w-[796px] mx-auto laptop:mx-0 text-center laptop:text-left">
              <SectionTitle className="mb-5 lg:mb-8">
                {/* First Co-developed <br /> AI App with Google */}
                {/* First Co-developed <br /> AI app with Google Cloud */}
                The first AI operating system using Inflectiv built in conjunction with Google
              </SectionTitle>
              <SectionDetail>
                {/* Allowing all AI apps to be run on a decentralised, secure network, powered by the users, with multiple AAA AI applications launching within the first year. */}
                {/* Helios is an AI agent support bot app that we are creating with Google Cloud, fueled by Inflectiv's premium training data and custom Gemini integration for superior AI performance. */}
                Helios is an AI agent infrastructure, which allows developers to easily create AI agent types, fueled by Inflectiv's premium training data and custom Google Gemini integration for superior AI performance
              </SectionDetail>

              <div className="mt-6 laptop:mt-9 flecx items-center max-w-[200px] mx-auto laptop:mx-0 laptop:max-w-[300px]">
                <img
                  src={`${process.env.REACT_APP_S3_ASSETS_URL}helios-icon.webp`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="col-span-1 px-5 py-10 pt-0 laptop:p-[50px]">
            <div className="relative flex items-center justify-center w-full h-[400px] md:h-[500px] laptop:h-[757px] after:!z-0">
              <div className="relative videoContain w-full h-full before:bg-leftGlassEffect before:bg-no-repeat after:bg-rightGlassEffect after:bg-no-repeat">
                {/* <video
                  ref={videoRef}
                  loading="lazy"
                  poster={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-poster.webp`}
                  autoPlay
                  loop
                  playsInline
                  className="w-[139px] md:w-[230px] laptop:w-[350px] mx-auto h-full relative z-10 rounded-[16px] md:rounded-[28px] laptop:rounded-[45px]"
                >
                  <source src={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-vd.${isMobile ? "mp4" : "webm"}`} type={`video/${isMobile ? "mp4" : "webm"}`} />
                </video> */}
                {/* <video
                  ref={videoRef}
                  loading="lazy"
                  poster={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-poster.webp`}
                  autoPlay
                  loop
                  playsInline
                  className="w-[139px] md:w-[230px] laptop:w-[350px] mx-auto h-full relative z-10 rounded-[16px] md:rounded-[28px] laptop:rounded-[45px]"
                >
                  <source src={mobileApp} type="video/mp4" />
                </video> */}

                <VideoPlayer />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default AiApp;
