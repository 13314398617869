import React from "react";
import { Container, SectionDetail, SectionTitle, Button } from "components";
import { ARROW_ICONS } from "utils/constants";
import { useNavigate } from "react-router-dom";

function InflectivForDevelopers() {
  const navigate = useNavigate();
  return (
    <section className="relative py-[50px] pb-[0] lg:pt-[50px] laptop:pb-[180px] bg-black overflow-hidden -gradient-cirlce -before:top-0 -before:right-0">
      <Container className="max-w-inner z-20">
        <div className="grid laptop:grid-cols-2 gap-5 items-center">
          <div className="col-span-1 relative z-10">
            <div className="max-w-[90%] lg:max-w-[75%] laptop:max-w-[640px] mx-auto laptop:mx-0 text-center laptop:text-left">
              <SectionTitle className="mb-5 lg:mb-8">
                {/* <span className='text-[24px] md:text-[36px] laptop:text-[44px] leading-normal laptop:leading-[44px] font-bold capitalize block -tracking-16 laptop:-tracking-28'> </span> */}
                Inflectiv for Developers (Beta)
              </SectionTitle>
              <SectionDetail>
                {/* As an Inflectiv astronaut we are with you side by side as you blast to the stars with your AI applications. */}
                As an Inflectiv astronaut we are with you side by side as you
                blast to the stars with your AI applications.
              </SectionDetail>

              <div className="mt-6 lg:mt-9">
                <Button
                  className="primary-button relative px-4 py-3 lg:px-6 lg:py-[15px]"
                  onClick={() => navigate("/developers")}
                >
                  Know More
                  <span
                    className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                  >
                    {ARROW_ICONS.circleArrow}
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <div className="col-span-1 h-[350px] lg:h-[500px] xl:h-[600px]">
            <div className="relative laptop:absolute bottom-0 laptop:-right-[100px] z-0 laptop:w-[50%] flex items-center justify-center laptop:justify-end h-full laptop:h-auto">
              <img
                src={`${process.env.REACT_APP_S3_ASSETS_URL}astro.webp`}
                alt=""
                className="h-full object-cover max-w-[90%]"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default InflectivForDevelopers;
