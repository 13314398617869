import React from "react";
import { Button, Container } from "components";
import { ARROW_ICONS } from "utils/constants";
import { motion } from "framer-motion";
import { useMediaQuery } from "hooks/useMediaQuery";

function Banner() {
  const isMobile = useMediaQuery("(max-width: 767px)");

  const handleBtnClick = () => {
    window.open("https://developer.inflectiv.ai", "_blank");
  };
  const handleBtnClickMarketplace = () => {
    window.open("https://marketplace.inflectiv.ai", "_blank");
  };

  return (
    <>
      <section
        className="relative w-full h-full py-28 landscape:py-[180px] landscape:lg:pt-[240px] landscape:lg:pb-[122px] lg:pt-[240px] lg:pb-[122px] z-30 flex flex-col justify-center overflow-hidden bg-licorice min-h-[525px] md:min-h-max max-h-[585px]"
      // id="hero-section"
      >
        <div className="absolute top-0 left-0 w-full h-full before:absolute before:bottom-0 before:top-0 before:w-full before:h-full">
          {/* <video
            loading="lazy"
            poster={`${process.env.REACT_APP_S3_ASSETS_URL}hero-banner-poster.webp`}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source
              src={`${process.env.REACT_APP_S3_ASSETS_URL}hero-banner.mp4`}
              type="video/mp4"
            />
          </video> */}

          <img
            src={`${process.env.REACT_APP_S3_ASSETS_URL}${isMobile ? "developer-banner-mob.jpg" : "developer-banner-bg.jpg"
              }`}
            alt=""
            className="h-full w-full object-cover relative -bottom-10 lg:bottom-0"
          />
        </div>

        <Container className="w-full h-full z-[2] max-w-developer">
          <div className="max-w-[100%] lg:max-w-[75%] mx-auto laptop:mx-0 laptop:max-w-[740px] h-full text-center laptop:text-left">
            <motion.h1
              style={{
                fontSize: "clamp(1.875rem, 1.1852rem + 2.94314vw, 3.625rem)",
                lineHeight: "clamp(32px, 1.1852rem + 2.94314vw, 60px)",
              }}
              className={`font-bold tracking-tighter laptop:-tracking-[3.18px] text-dim mb-6`}
              initial={{
                opacity: 0,
                y: 20,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1,
                  delay: 0.2,
                },
              }}
              viewport={{ once: true }}
            >
              Inflectiv For <br /> Developers (Beta)
            </motion.h1>

            <motion.p
              initial={{
                opacity: 0,
                y: 20,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1,
                  delay: 0.4,
                },
              }}
              viewport={{ once: true }}
              style={{
                fontSize: "clamp(1.125rem, 1.07339rem + 0.258065vw, 1.375rem)",
                lineHeight: "clamp(24px, 1.07339rem + 0.258065vw, 28px)",
              }}
              className={`font-medium text-dim/80 -tracking-08`}
            >
              Build, train, and elevate AI apps with decentralized, high-quality
              AI training data and custom AI integrations.
            </motion.p>

            <div className="flex items-center justify-center laptop:justify-start flex-wrap md:flex-nowrap gap-4 mt-6 lg:mt-9">
              <Button
                className={`${isMobile ? "light-button" : "primary-button"
                  } relative px-4 py-3 lg:px-6 lg:py-[15px]`}
                onClick={handleBtnClick}
                initial={{
                  opacity: 0,
                  y: 20,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: "spring",
                    duration: 1,
                    delay: 0.6,
                  },
                }}
                viewport={{ once: true }}
              >
                Get Started
                <span
                  className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                >
                  {ARROW_ICONS.circleArrow}
                </span>
              </Button>
              <Button
                className="bg-lightGray text-white border-[1px] border-[#373839] hover:!text-white/70 relative px-4 py-3 lg:px-6 lg:py-[15px]"
                onClick={handleBtnClickMarketplace}
                initial={{
                  opacity: 0,
                  y: 20,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: "spring",
                    duration: 1,
                    delay: 0.7,
                  },
                }}
                viewport={{ once: true }}
              >
                Explore The Marketplace
                <span
                  className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                >
                  {ARROW_ICONS.circleArrow}
                </span>
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Banner;
