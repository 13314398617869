import React, { useState } from "react";
import {
  Container,
  SectionDetail,
  SectionTitle,
  Button,
  SignupModal,
} from "components";
import { ARROW_ICONS } from "utils/constants";

export default function InflectivNetwork({ darkMode }) {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

  const bodyElement = document.body;
  isShowWhiteListModal
    ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <section
        className={`relative py-[90px] pb-[50px] laptop:pt-[180px] laptop:pb-[90px] overflow-hidden ${darkMode ? "bg-black" : "bg-white"
          }`}
      >
        <Container className="max-w-inner">
          <div className="text-center max-w-[90%] lg:max-w-[75%] laptop:max-w-[900px] mx-auto">
            <SectionTitle
              className={`mb-5 lg:mb-8 ${darkMode ? "" : "!text-black"}`}
            >
              Decentralized AI Data Infrastructure
            </SectionTitle>
            <SectionDetail className={`${darkMode ? "" : "!text-black"}`}>
              {/* The Inflectiv network runs on a cluster of nodes, which run app
              processing, manage training data and connect to the right AI for
              applications running on the network. */}
              {/* The Inflectiv network runs on a cluster of nodes, which run app processing, manage training data and connect to the right AI for applications running on the network. */}
              Behind every dataset is the Inflectiv Network—a robust decentralized infrastructure designed to process, tokenize, and distribute high-quality data assets. Built for transparency, scalability, security and community empowerment, the Inflectiv Network connects contributors and AI apps in a seamless ecosystem. Inflectiv is the backbone of the future data economy.
            </SectionDetail>

            <div className="mt-6 lg:mt-9">
              <Button
                className={`primary-button relative px-4 py-3 lg:px-6 lg:py-[15px] ${darkMode && "primary-button"
                  }`}
                onClick={() => setShowWhiteListModal(true)}
              >
                Sign up for Whitelist
                <span
                  className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                >
                  {ARROW_ICONS.circleArrow}
                </span>
              </Button>
            </div>
          </div>
        </Container>

        <div className="relative z-10 max-w-[1200px] mx-auto">
          {/* <img
              src={
                darkMode
                  ? `${process.env.REACT_APP_S3_ASSETS_URL}networks-route-dark.webp`
                  : `${process.env.REACT_APP_S3_ASSETS_URL}networks-route.webp`
              }
              alt="inflectiv network"
              className="max-h-full max-w-full"
            /> */}

          <video
            loading="lazy"
            poster={`${process.env.REACT_APP_S3_ASSETS_URL}inflectiv_network_poster.jpg`}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source
              src={`${process.env.REACT_APP_S3_ASSETS_URL}inflectiv_network.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </section>
      {/* testing commit */}
      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}
