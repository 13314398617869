import React from "react";
import Banner from "./sections/Banner";
import ToolsData from "./sections/ToolsData";
import WhyInflectiv from "./sections/WhyInflectiv";
import ProductGrowth from "./sections/ProductGrowth";
import StartDeveloping from "./sections/StartDeveloping";
import { Helmet } from "react-helmet";

function Developers() {
  return (
    <>
      <Helmet>
        <title>
          Inflectiv Developers : Build, Train, and Scale Decentralized AI Apps
        </title>
        <meta
          name="description"
          content="Become a part of Inflectiv's Developer Ecosystem to harness the power of  AI technology.  Build, Train, and Scale Decentralized AI Apps and datasets powered by Gemini."
        />

        <meta
          property="og:title"
          content="Inflectiv Developers : Build, Train, and Scale Decentralized AI Apps"
        />
        <meta
          property="og:description"
          content="Become a part of Inflectiv's Developer Ecosystem to harness the power of  AI technology.  Build, Train, and Scale Decentralized AI Apps and datasets powered by Gemini."
        />

        <meta
          name="twitter:title"
          content="Inflectiv Developers : Build, Train, and Scale Decentralized AI Apps"
        />
        <meta
          name="twitter:description"
          content="Become a part of Inflectiv's Developer Ecosystem to harness the power of  AI technology.  Build, Train, and Scale Decentralized AI Apps and datasets powered by Gemini."
        ></meta>
      </Helmet>
      <Banner />
      <ToolsData />
      <WhyInflectiv />
      <ProductGrowth />
      <StartDeveloping />
    </>
  );
}

export default Developers;
