import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { Layout } from "components";
import Homepage from "page/home";
import Developers from "page/developer";
import TermsAndConditions from "page/content-pages/TermsAndConditions";
import PrivacyPolicy from "page/content-pages/PrivacyPolicy";
import BlogDetailPage from "page/blog-detail";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        <Route
          path="/developers"
          element={
            <Layout>
              <Developers />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route path="/blog/:blog-name" element={
          <Layout>
            <BlogDetailPage/>
          </Layout>
        }
        />
        <Route path="/*" element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
}

export default App;
