import React, { useState } from "react";
import {
    Button,
    Container,
    SectionTag,
    SignupModal,
} from "components";
import { ARROW_ICONS } from "utils/constants";
import { motion } from "framer-motion";

function Banner() {
    const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

    const bodyElement = document.body;
    isShowWhiteListModal
        ? bodyElement.classList.add("overflow-hidden")
        : bodyElement.classList.remove("overflow-hidden");

    return (
        <>
            <section
                className="relative w-full min-h-screen h-full py-24 landscape:py-[150px] md:py-36 z-30 flex flex-col justify-center overflow-hidden bg-black"
                id="hero-section"
            >
                <div className="vdGradient absolute top-0 left-0 w-full min-h-screen h-full before:absolute before:bottom-0 before:top-0 before:w-full before:h-full">
                    <video
                        loading="lazy"
                        poster={`${process.env.REACT_APP_S3_ASSETS_URL}hero-banner-poster.webp`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="w-full h-full object-cover"
                    >
                        <source
                            src={`${process.env.REACT_APP_S3_ASSETS_URL}hero-banner.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <Container className="w-full h-full z-[2] max-w-inner">
                    <div className="max-w-[90%] lg:max-w-[75%] laptop:max-w-[920px] mx-auto h-full text-center">
                        <div className="relative xl:mb-24">
                            <SectionTag className="mb-6 lg:mb-[32px] mx-auto">
                                <img
                                    className="w-full max-w-[400px] mx-auto"
                                    src={`${process.env.REACT_APP_S3_ASSETS_URL}hero-section-tagline.png`}
                                    alt="tagline"
                                />
                                {/* Adopt Inflectiv, Enhance AI.
                                <span className="w-4 md:w-5 laptop:w-7 *:w-full">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="24"
                                        viewBox="0 0 28 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.52344 11.3684L13.2994 8.776L15.8919 -1.14311e-05L18.4843 8.776L27.2603 11.3684L18.4843 13.9608L15.8919 22.7368L13.2994 13.9608L4.52344 11.3684Z"
                                            fill="#F9F9F9"
                                        />
                                        <path
                                            d="M0.738281 3.78955L3.66361 2.92542L4.52775 8.6224e-05L5.39188 2.92542L8.31721 3.78955L5.39188 4.65368L4.52775 7.57902L3.66361 4.65368L0.738281 3.78955Z"
                                            fill="#F9F9F9"
                                        />
                                        <path
                                            d="M4.52344 20.2104L7.44877 19.3463L8.3129 16.421L9.17704 19.3463L12.1024 20.2104L9.17704 21.0746L8.3129 23.9999L7.44877 21.0746L4.52344 20.2104Z"
                                            fill="#F9F9F9"
                                        />
                                    </svg>
                                </span> */}
                            </SectionTag>
                            <motion.h1
                                style={{
                                    fontSize: "clamp(2.5rem, 1.55936rem + 4.01338vw, 6.25rem)",
                                    lineHeight: "clamp(44px, 1.55936rem + 4.01338vw, 100px)",
                                }}
                                className="font-bold tracking-[-2.48px] laptop:tracking-[-4.48px] mb-5 md:mb-[16px] text-white"
                                initial={{
                                    opacity: 0,
                                    y: 20,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        duration: 1,
                                        delay: 0.2,
                                    },
                                }}
                                viewport={{ once: true }}
                            >
                                Powering < br  className="md:hidden"/>Global AI
                            </motion.h1>
                            <motion.p
                                initial={{
                                    opacity: 0,
                                    y: 20,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        duration: 1,
                                        delay: 0.4,
                                    },
                                }}
                                viewport={{ once: true }}
                                style={{
                                    fontSize: "clamp(1.25rem, 1.22419rem + 0.129032vw, 1.375rem)",
                                    lineHeight: "clamp(22px, 1.22419rem + 0.129032vw, 32px)",
                                }}
                                className={`font-normal text-dim/80 -tracking-08`}
                            >
                                Data is the oil that fuels the AI engines of the world. Inflectiv empowers billions to transform this data into tokenized, AI-ready dataset assets—owned, governed, and monetized by the community, across every sector.
                            </motion.p>

                            <div className="flex items-center justify-center flex-wrap md:flex-nowrap gap-5 md:gap-10 my-6 lg:mt-9">
                                <Button
                                    className="light-button relative px-4 py-3 lg:px-6 lg:py-[15px]"
                                    onClick={() => setShowWhiteListModal(true)}
                                    initial={{
                                        opacity: 0,
                                        y: 20,
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                        transition: {
                                            type: "spring",
                                            duration: 1,
                                            delay: 0.6,
                                        },
                                    }}
                                    viewport={{ once: true }}
                                >
                                    Sign up for Whitelist
                                    <span
                                        className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                                    >
                                        {ARROW_ICONS.circleArrow}
                                    </span>
                                </Button>
                            </div>
                            <motion.p
                                initial={{
                                    opacity: 0,
                                    y: 20,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        duration: 1,
                                        delay: 0.8,
                                    },
                                }}
                                viewport={{ once: true }}
                                style={{
                                    fontSize: "clamp(16, 1.22419rem + 0.129032vw, 18px)",
                                    lineHeight: "clamp(24px, 1.22419rem + 0.129032vw, 28px)",
                                }}
                                className={`font-normal text-dim/80 -tracking-08`}
                            >
                                Join the whitelist for free nodes, $IAI tokens, and priority access.
                            </motion.p>
                        </div>
                    </div>
                </Container>

                {/* <div className="absolute bottom-10 left-[44%] lg:left-[48%] -translate-x-50 w-10 flex items-center justify-center animate-bounce">
                    {ARROW_ICONS.scrollArrow}
                </div> */}
                <div className="absolute bottom-10 left-[0%] lg:left-[0%] -translate-x-50 w-full flex items-center justify-center animate-bounce">
                    {ARROW_ICONS.scrollArrow}
                </div>
            </section >

            {isShowWhiteListModal && (
                <SignupModal
                    isToggled={isShowWhiteListModal}
                    setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
                />
            )
            }
        </>
    );
}

export default Banner;
