import React from "react";
import { Navigate } from "react-router-dom";

export default function NewsCard({ blog }) {
  const { imgSrc, heading, date, active, sub_heading, link } = blog;

  //   const handleNavigate = () => {
  //     window.location.href = `/blog/${link}`;
  //   };

  return (
    <div className="relative rounded-[32px] border border-black/[0.16] hover:border-orange bg-dim p-2 overflow-hidden h-full">
      <a
        href={`/blog/${link}`}
        className="block w-full h-full cursor-pointer group relative z-10"
      >
        <div className="h-[275px] w-full rounded-3xl overflow-hidden mb-6">
          <img src={imgSrc} alt="" className="w-full object-cover h-full" />
        </div>

        <div className="px-3 flex flex-col justify-between w-full h-[calc(100%-310px)]">
          <div className="flex flex-col justify-between mb-[15px]">
            <h3 className="text-[22px]/8 font-semibold line-clamp-2 -tracking-08 text-black duration-300 group-hover:text-orange mb-3 min-h-[65px]">
              {heading}
            </h3>
            <p className="text-sm/5 font-normal text-smokyBlack/85 truncate">
              {sub_heading}
            </p>
          </div>

          <div className="flex items-center justify-between gap-5">
            <div className="text-smokyBlack/50 text-base/5 font-normal -tracking-03">
              {date}
            </div>
            <div className="inline-flex items-center justify-center w-10 h-10 lg:w-[50px] lg:h-[50px] *:h-full *:w-full duration-300 text-black group-hover:text-orange">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
              >
                <g opacity="0.5">
                  <path
                    d="M26.5625 18.7505L32.8125 25.0005M32.8125 25.0005L26.5625 31.2505M32.8125 25.0005L17.1875 25.0005M6.25 25.0005C6.25 27.4628 6.73498 29.9009 7.67726 32.1758C8.61953 34.4507 10.0006 36.5176 11.7417 38.2587C13.4828 39.9998 15.5498 41.381 17.8247 42.3232C20.0995 43.2655 22.5377 43.7505 25 43.7505C27.4623 43.7505 29.9005 43.2655 32.1753 42.3232C34.4502 41.381 36.5172 39.9998 38.2582 38.2587C39.9993 36.5176 41.3805 34.4507 42.3227 32.1758C43.265 29.9009 43.75 27.4628 43.75 25.0005C43.75 20.0277 41.7746 15.2585 38.2583 11.7422C34.7419 8.22593 29.9728 6.25049 25 6.25049C20.0272 6.25049 15.2581 8.22593 11.7418 11.7422C8.22544 15.2585 6.25 20.0277 6.25 25.0005Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
