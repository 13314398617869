import React, { useState } from "react";
import {
  Container,
  SectionDetail,
  SectionTitle,
  Button,
  NodeCard,
  SignupModal,
} from "components";
import { ARROW_ICONS } from "utils/constants";

function NetworkNodes() {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

  const bodyElement = document.body;
  isShowWhiteListModal
    ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <section className="relative pt-[90px] laptop:pt-[180px] pb-[90px] bg-black gradient-cirlce before:left-0 before:top-0 before:rotate-[-111deg]  overflow-hidden">
        <Container className="max-w-[1680px] relative z-20">
          <div className="text-center mx-auto md:max-w-[80%] laptop:max-w-full">
            <div className="mb-8 h-8 md:h-[34px] flex items-center justify-center text-xs md:text-xsm laptop:text-sm/none font-medium">
              <span className="flex items-center justify-center p-2 md:p-[10px] bg-orange text-black h-full rounded-tl-[10px] rounded-bl-[10px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.33333 1.33325C6.33333 1.05711 6.10948 0.833252 5.83333 0.833252C5.55719 0.833252 5.33333 1.05711 5.33333 1.33325V2.33325H5.16667C3.69391 2.33325 2.5 3.52716 2.5 4.99992V5.49992H14.5V4.99992C14.5 3.52716 13.3061 2.33325 11.8333 2.33325H11.6667V1.33325C11.6667 1.05711 11.4428 0.833252 11.1667 0.833252C10.8905 0.833252 10.6667 1.05711 10.6667 1.33325V2.33325H6.33333V1.33325ZM2.5 6.49992H14.5V11.9999C14.5 13.4727 13.3061 14.6666 11.8333 14.6666H5.16667C3.69391 14.6666 2.5 13.4727 2.5 11.9999V6.49992Z"
                    fill="#0B0B0B"
                  />
                </svg>{" "}
                Date
              </span>
              <span className="p-2 h-full rounded-tr-[10px] rounded-br-[10px] bg-orange/20 text-orange min-w-[71px] inline-block">
                {" "}
                TBA
              </span>
            </div>
            <SectionTitle className="mb-5 lg:mb-8 max-w-[1000px] mx-auto">
              {/* Phase 1 - Network Node Event */}
              {/* Purchase your node and earn $IAI token emissions */}
              Purchase your node and earn $IAI token emissions
            </SectionTitle>
            <SectionDetail className="max-w-[900px] mx-auto">
              {/* Empowering Transactions, Governance, and Innovation. Earn rewards by staking $IAI Tokens. */}
              {/* Join the forefront of the Inflectiv Blockchain movement with the
              launch of Phase 01: Network Node Event. */}
              {/* Owning an Inflectiv Node means unlocking daily $IAI token emissions for the next five years. Your node works 24/7, contributing to the decentralized AI network, and in return, you’re rewarded with tokens every single day. */}
              Owning an Inflectiv Node means unlocking daily $IAI token emissions for the next five years. Your node works 24/7, contributing to the decentralized AI network, and in return, you’re rewarded with tokens every single day.
            </SectionDetail>

            <div className="mt-5 lg:mt-9">
              <Button
                className="primary-button relative px-4 py-3 lg:px-6 lg:py-[15px]"
                onClick={() => setShowWhiteListModal(true)}
              >
                Sign up for Whitelist
                <span
                  className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                >
                  {ARROW_ICONS.circleArrow}
                </span>
              </Button>
            </div>
          </div>

          <div className="relative mt-[50px] laptop:mt-[100px] grid grid-flow-row lg:grid-flow-col auto-cols-[minmax(0,380px)] lg:grid-cols-3 justify-center gap-4 laptop:gap-5 max-w-[1360px] mx-auto">
            <div className="col-span-1">
              <NodeCard
                // nodebg={buyIcon}
                nodebg={`${process.env.REACT_APP_S3_ASSETS_URL}buy-icon.png`}
                bgPattern="before:bg-buyPattern"
              // nodeIcon={`${process.env.REACT_APP_S3_ASSETS_URL}purchase-icon.webp`}
              >
                Purchase
                <span className="block text-[26px] laptop:text-[32px] leading-7 laptop:leading-10 font-bold">
                  Your Node
                </span>
              </NodeCard>
            </div>
            <div className="col-span-1">
              <NodeCard
                // nodebg={nodeIcon}
                nodebg={`${process.env.REACT_APP_S3_ASSETS_URL}node-icon.png`}
                bgPattern="before:bg-nodePattern"
              // nodeIcon={`${process.env.REACT_APP_S3_ASSETS_URL}run-icon.webp`}
              >
                Run
                <span className="block text-[26px] laptop:text-[32px] leading-7 laptop:leading-10 font-bold">
                  Your Node
                </span>
              </NodeCard>
            </div>
            <div className="col-span-1">
              <NodeCard
                // nodebg={emissinIcon}
                nodebg={`${process.env.REACT_APP_S3_ASSETS_URL}emission-icon.png`}
                bgPattern="before:bg-emissionPattern"
              // nodeIcon={`${process.env.REACT_APP_S3_ASSETS_URL}earn-icon.webp`}
              >
                Earn Token Emissions
                <span className="block text-[26px] laptop:text-[32px] leading-7 laptop:leading-10 font-bold">
                  And Monthly Revenues
                </span>
              </NodeCard>
            </div>
          </div>
        </Container>
      </section>

      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}

export default NetworkNodes;
