import { getEndPath } from "utils/helper";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { BLOG_API_URL } from "utils/constants";

const BlogDetailSection = () => {
  const [loading, setLoading] = useState(true);
  const [blogDetail, setBlogDetail] = useState({});
  const [error, setError] = useState(false);

  const fetchNewsDetail = useCallback(async () => {
    try {
      const blogLink = getEndPath();

      const res = await axios.post(`${BLOG_API_URL}/inflective_docs/details`,
        {
          blogLink: blogLink,

        });

      const { date, img_src, title, content } = res.data;
      const resObject = {
        blogLink: res.data["blog-link"] ? res.data["blog-link"] : null,
        date: date ? date : null,
        imgSrc: img_src ? img_src : null,
        title: title ? title : null,
        content: content ? content : null,
      };
      setBlogDetail(resObject);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <>
      <div className="blog-detail-page bg-contentPageBg relative w-full h-full bg-fixed bg-no-repeat bg-cover">
        <div className="blog-details-container relative">
          {loading ? (
            <p>Loading</p>
          ) : !error ? (
            <div>




              <div style={{ textAlign: "center" }}>
                {/* Title Section */}
                {blogDetail.title && (
                  <h1>
                    {blogDetail.title}
                  </h1>
                )}

                {/* Date Section */}
                {blogDetail.date && (
                  <p className="date">
                    {blogDetail.date}
                  </p>
                )}

                {/* Image Section */}
                {blogDetail.imgSrc && (
                  <img
                    src={blogDetail.imgSrc}
                    alt={blogDetail.title || "Blog Image"}
                    style={{ width: "100%", height: "auto", marginBottom: "30px", borderRadius: "24px" }}
                  />
                )}
              </div>

              {/* Content Section (Dangerously Set HTML) */}
              {blogDetail.content && (
                <div
                  style={{ textAlign: "left", lineHeight: "1.6" }}
                  dangerouslySetInnerHTML={{ __html: blogDetail.content }}
                ></div>
              )}

              {/* Blog Link */}
              {/* {blogDetail.blogLink && (
            <a
              href={blogDetail.blogLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                marginTop: "15px",
                padding: "10px 20px",
                backgroundColor: "#007BFF",
                color: "#fff",
                borderRadius: "5px",
                textDecoration: "none",
              }}
            >
              Read More
            </a>
          )} */}
            </div>
          ) : (
            <p>Error</p>
          )}
        </div>
      </div>
    </>
  );
};


export default BlogDetailSection;