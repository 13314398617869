import NewsCard from "../cards/NewsCard";
import { ARROW_ICONS, BLOG_API_URL } from "utils/constants";
import axios from "axios";
import { useState, useCallback, useEffect } from "react";
import { Button, Container, SectionTitle } from "components";

const BlogSection = ({ darkMode }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const pageSize = 10;

  const blogData = useCallback(async () => {
    try {
      const pageNo = currentPage;
      setLoading(true);
      const res = await axios.post(`${BLOG_API_URL}/inflective_docs`, { pageNo, pageSize });
      const { data } = res || {};
      const { docs = [], count = 0 } = data || {};
      setBlogs(docs);
      setCount(count);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    blogData();
  }, [currentPage]);


  return (
    <section className={`relative py-[100px] laptop:py-[140px] overflow-hidden ${darkMode ? "bg-black" : "bg-white"
      }`}>
      <Container className="!max-w-[1680px] relative z-20">
        <div className="flex items-center justify-between gap-5 flex-col md:flex-row mb-9 md:mb-[55px]">
          <SectionTitle className={`${darkMode ? "" : "!text-black"}`}>
            Articles
          </SectionTitle>
          {/* <Button
            className="dark-button relative px-4 py-[11px] lg:px-6 lg:py-[14px]"
          >
            View All
            <span
              className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
            >
              {ARROW_ICONS.circleArrow}
            </span>
          </Button> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {blogs?.map((item, index) => (
            <div key={index} className="col-span-1">
              <NewsCard blog={item} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default BlogSection;
