import React, { useState } from "react";
import { ARROW_ICONS } from "utils/constants";
import {
  SectionTitle,
  Container,
  SectionDetail,

  SignupModal,
} from "components";
import { useMediaQuery } from "hooks/useMediaQuery";

function StartDeveloping() {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");

  // const handleBtnClick = () => {
  //   window.open('https://forms.gle/58fMyc4LNaDQrmDF8', '_blank'); 
  // };

  const bodyElement = document.body;
  isShowWhiteListModal
    ? bodyElement.classList.add("overflow-hidden")
    : bodyElement.classList.remove("overflow-hidden");

  return (
    <>
      <section className="relative bg-black overflow-hidden laptop:min-h-[900px] xl:min-h-[1000px] flex before:absolute before:right-0 before:bottom-0 before:w-full before:h-full before:bg-blurEffect before:bg-cover before:bg-center before:z-10">
        <Container className="max-w-[1870px] relative !px-0 flex-1">
          <div className="absolute top-0 left-0">
            <img
              src={`${process.env.REACT_APP_S3_ASSETS_URL}planet.png`}
              alt=""
              className="w-[65%] max-w-[1000px]"
            />
          </div>

          <div className="absolute right-0 md:-right-[10%] bottom-0 astrChar w-full text-center md:text-right">
            {/* <img src={`${process.env.REACT_APP_S3_ASSETS_URL}${isMobile ? "astroChar.png" : "astroCharMobile.png"}`} alt="" className="relative z-10 block max-w-[375px] md:max-w-full mx-auto" /> */}
            <img src={`${process.env.REACT_APP_S3_ASSETS_URL}${isMobile ? "astroCharMobile.png" : "astroChar.png"}`} alt="Astro Character" className="relative z-10 block max-w-[375px] md:max-w-full mx-auto" />
          </div>

          <div className="max-w-developer mx-auto px-8 pb-[350px] md:py-[150px] pt-[100px] laptop:py-[100px] h-full flex flex-col justify-center bg-stars bg-cover bg-left-top">
            <div className="relative z-10 text-center md:text-left mx-auto md:mx-0 md:max-w-[70%] lg:max-w-[60%] laptop:max-w-[650px]">
              <SectionTitle className="mb-5 lg:mb-8 xl:-tracking-[5px]">
                <span className="font-normal block text-[26px]/[30px] uppercase -tracking-16 mb-3">
                  Why Wait?
                </span>
                Start Developing with Inflectiv Today
              </SectionTitle>
              <SectionDetail className="">
                Join Inflectiv and be part of the next wave of decentralized AI
                applications. By building on Inflectiv, you’ll not only benefit
                from premium data and tools but also open doors to powerful
                resources that will elevate your AI app to new heights.
              </SectionDetail>

              <div className="mt-10 lg:mt-[50px]">
                <a
                  className="primary-button inline-flex rounded-full relative px-4 py-3 lg:px-6 lg:py-[15px]"
                  href="https://developer.inflectiv.ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                  <span
                    className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                  >
                    {ARROW_ICONS.circleArrow}
                  </span>
                </a>

                <p className="text-xsm lg:text-sm/6 text-white/85 font-medium mt-3 lg:mt-4">
                  Claim your spot, access grants, and start building for the
                  future of AI.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}

export default StartDeveloping;
