const usedPositions = new Set();

export const generateRandomPosition = () => {
  let position;

  do {
    const angle = Math.random() * 360;
    const left = 50 + 50 * Math.cos(angle * (Math.PI / 180));
    const top = 50 + 50 * Math.sin(angle * (Math.PI / 180));
    position = `${left.toFixed(2)},${top.toFixed(2)}`; // Create a unique key for the position
  } while (usedPositions.has(position));

  usedPositions.add(position); // Add the new position to the set
  const [left, top] = position.split(",").map(Number);
  return { left, top };
};



export const getEndPath = () => {
  const pathname = window.location.pathname;

  // Extract the last segment of the path
  const endPath = pathname.substring(pathname.lastIndexOf("/") + 1);
  return decodeURIComponent(endPath);
};