import React, { useRef } from "react";
import { Container } from "components";
import { ARROW_ICONS, INFECTIVlOGOS, SOCIAL_ICONS } from "utils/constants";
import { motion } from "framer-motion";

function Footer() {
    const ref = useRef(null);
    return (
        <footer
            className="relative z-10 pt-[25px] bg-white overflow-hidden"
            ref={ref}
        >
            <Container className="h-full">
                <div className="grid md:grid-cols-2 gap-4 md:gap-10 h-full pb-8 items-center">
                    <div className="col-span-1 z-10 h-full">
                        <div className="flex items-center justify-center md:justify-start">
                            <div className="relative max-w-[45px] lg:max-w-[52px] w-full h-auto *:w-full *:h-full">
                                <img
                                    src={`${process.env.REACT_APP_S3_ASSETS_URL}infectivColorLogo.webp`}
                                    alt="inflectiv"
                                />
                            </div>

                            <div className="text-black border-l text-xs xl:text-sm border-black pl-2 ml-2 lg:pl-4 lg:ml-4 font-medium leading-none md:leading-[20px] capitalize">
                                The global engine for Decentralized AI.
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <ul className="flex gap-4 laptop:gap-5 socials justify-center md:justify-end text-blue">
                            {SOCIAL_ICONS.map((icon) => (
                                <li
                                    className="flex items-center justify-center"
                                    key={icon.name}
                                >
                                    <a
                                        href={icon.url}
                                        className="w-10 h-10 lg:w-[44px] lg:h-[44px] p-[6px] border-[1.5px] border-black/[.17] rounded-full flex items-center justify-center cursor-pointer text-black hover:text-black/70 duration-300 hover:bg-black hover:text-white"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {icon.svg}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="md:landscape:pb-3 lg:landscape:pb-5 md:pb-5 laptop:py-[23px] relative text-black text-xs xl:text-sm font-normal">
                    <div className="grid md:grid-cols-4 md:landscape:grid-cols-3 laptop:landscape:grid-cols-4 xl:landscape:grid-cols-5 xl:grid-cols-5 items-end gap-2 md:gap-4">
                        <div className="col-span-1 md:landscape:col-span-1 laptop:landscape:col-span-1 md:col-span-2 laptop:col-span-1 text-center md:text-left">
                            <p>Copyright © {new Date().getUTCFullYear()} Inflectiv</p>
                        </div>
                        <div className="col-span-1 md:landscape:col-span-1 laptop:landscape:col-span-2 xl:landscape:col-span-3 md:col-span-4 laptop:col-span-2 xl:col-span-3 order-3 md:landscape:order-none laptop:order-none">
                            <motion.div className="relative w-full min-h-[50px] sm:min-h-[90px] md:landscape:min-h-[120px] md:min-h-[150px] laptop:min-h-[229px] *:w-full *:h-full *:absolute *:-bottom-[10px] *:md:-bottom-[40px] *:md:landscape:-bottom-[45px] *:lg:landscape:-bottom-[45px] *:laptop:-bottom-[78px] *:md:landscape:w-full *:lg:landscape:w-[85%] *:laptop:landscape:w-full md:landscape:flex md:landscape:justify-center">
                                {INFECTIVlOGOS.footerLogo}
                            </motion.div>
                        </div>
                        <div className="col-span-1 md:landscape:col-span-1 laptop:landscape:col-span-1 md:col-span-2 laptop:col-span-1">
                            <div className="flex items-center justify-center md:justify-end gap-4 xl:gap-6">
                                <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 hover:text-orange hover:underline whitespace-nowrap"
                                >
                                    Privacy Policy
                                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full">
                                        {ARROW_ICONS.externalLink}
                                    </span>
                                </a>
                                <a
                                    href="/terms-and-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 hover:text-orange hover:underline whitespace-nowrap"
                                >
                                    Terms & Conditions 
                                    <span className="w-[14px] h-[14px] flex items-center justify-center *:w-full *:h-full">
                                        {ARROW_ICONS.externalLink}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
