import React from "react";
import { Container, SectionDetail, SectionTitle } from "components";
import { INFECTIVlOGOS } from "utils/constants";

function DecentratizedFuture() {
  return (
    <section className="relative bg-lotion py-[90px] laptop:py-[180px] overflow-hidden">
      <Container className="max-w-inner z-20">
        <div className="grid laptop:grid-cols-2 gap-12 md:gap-20 laptop:gap-8 items-center">
          <div className="col-span-1">
            <div className="max-w-[90%] lg:max-w-[75%] laptop:max-w-[600px] mx-auto laptop:mx-0 text-center laptop:text-left">
              <SectionTitle className="text-lightGray mb-5 lg:mb-8">
                The Future of Data in AI
              </SectionTitle>
              <SectionDetail className="text-lightGray/85 mb-7 lg:mb-10">
                Inflectiv AI transforms raw data into tokenized, AI-ready datasets deployed on a decentralized network. Own, govern, and monetize datasets while powering the next generation of AI applications.
              </SectionDetail>
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-2 md:gap-4 text-sm/6 md:text-[22px]/8 text-[#050505]/75">
                  <span className="size-10 flex items-center justify-center *:w-full *:h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9231 17.2308C16.4133 17.2308 16 17.644 16 18.1538C16 18.6636 16.4133 19.0769 16.9231 19.0769C17.4329 19.0769 17.8462 18.6636 17.8462 18.1538C17.8462 17.644 17.4329 17.2308 16.9231 17.2308ZM14.1538 18.1538C14.1538 16.6244 15.3937 15.3846 16.9231 15.3846C18.4525 15.3846 19.6923 16.6244 19.6923 18.1538C19.6923 19.6833 18.4525 20.9231 16.9231 20.9231C15.3937 20.9231 14.1538 19.6833 14.1538 18.1538Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3077 23.3846C23.7979 23.3846 23.3846 23.7979 23.3846 24.3077C23.3846 24.8175 23.7979 25.2308 24.3077 25.2308C24.8175 25.2308 25.2308 24.8175 25.2308 24.3077C25.2308 23.7979 24.8175 23.3846 24.3077 23.3846ZM21.5385 24.3077C21.5385 22.7783 22.7783 21.5385 24.3077 21.5385C25.8371 21.5385 27.0769 22.7783 27.0769 24.3077C27.0769 25.8371 25.8371 27.0769 24.3077 27.0769C22.7783 27.0769 21.5385 25.8371 21.5385 24.3077Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.2308 13.5385C28.721 13.5385 28.3077 13.9517 28.3077 14.4615C28.3077 14.9713 28.721 15.3846 29.2308 15.3846C29.7406 15.3846 30.1538 14.9713 30.1538 14.4615C30.1538 13.9517 29.7406 13.5385 29.2308 13.5385ZM26.4615 14.4615C26.4615 12.9321 27.7014 11.6923 29.2308 11.6923C30.7602 11.6923 32 12.9321 32 14.4615C32 15.9909 30.7602 17.2308 29.2308 17.2308C27.7014 17.2308 26.4615 15.9909 26.4615 14.4615Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.0178 15.477C29.4766 15.6992 29.6684 16.2514 29.4461 16.7102L26.2881 23.2287C26.0658 23.6875 25.5137 23.8792 25.0549 23.6569C24.5961 23.4347 24.4044 22.8826 24.6266 22.4238L27.7847 15.9052C28.0069 15.4464 28.5591 15.2547 29.0178 15.477ZM17.5181 18.9134C17.8248 18.5062 18.4036 18.4248 18.8108 18.7315L23.2678 22.0889C23.675 22.3957 23.7564 22.9744 23.4497 23.3816C23.1429 23.7888 22.5642 23.8702 22.157 23.5635L17.7 20.2061C17.2928 19.8994 17.2114 19.3206 17.5181 18.9134ZM16.3026 19.0754C16.7311 19.3516 16.8546 19.9229 16.5784 20.3514L10.9297 29.1155C10.6535 29.544 10.0823 29.6675 9.65377 29.3913C9.22526 29.1151 9.10178 28.5438 9.37797 28.1153L15.0267 19.3512C15.3029 18.9227 15.8741 18.7993 16.3026 19.0754Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92308 8C9.43288 8 9.84615 8.41328 9.84615 8.92308V22.4615C9.84615 24.5183 9.84812 25.968 9.99564 27.0652C10.1396 28.1359 10.4067 28.7318 10.8375 29.1626C11.2683 29.5933 11.8641 29.8604 12.9347 30.0044C14.032 30.1519 15.4817 30.1538 17.5385 30.1538H31.0769C31.5867 30.1538 32 30.5671 32 31.0769C32 31.5867 31.5867 32 31.0769 32H17.4699C15.4974 32 13.9233 32 12.6888 31.8341C11.4134 31.6626 10.363 31.2989 9.53207 30.468C8.70115 29.637 8.33742 28.5866 8.16594 27.3112C7.99996 26.0766 7.99998 24.5026 8 22.5301L8 8.92308C8 8.41328 8.41328 8 8.92308 8Z" fill="black" />
                    </svg>
                  </span>
                  Fine-tuned and tokenized data.
                </li>
                <li className="flex items-center gap-2 md:gap-4 text-sm/6 md:text-[22px]/8 text-[#050505]/75">
                  <span className="size-10 flex items-center justify-center *:w-full *:h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2683 20C13.2683 16.2822 16.2822 13.2683 20 13.2683C23.7178 13.2683 26.7317 16.2822 26.7317 20C26.7317 23.7178 23.7178 26.7317 20 26.7317C16.2822 26.7317 13.2683 23.7178 13.2683 20ZM20 15.0244C17.252 15.0244 15.0244 17.252 15.0244 20C15.0244 22.7479 17.252 24.9756 20 24.9756C22.7479 24.9756 24.9756 22.7479 24.9756 20C24.9756 17.2521 22.7479 15.0244 20 15.0244Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M31.7428 8.25718C32.0857 8.60008 32.0857 9.15603 31.7428 9.49893L28.584 12.6577C29.0316 12.626 29.4949 12.5703 29.9265 12.5183C30.1041 12.4969 30.2763 12.4762 30.4398 12.4581C30.9218 12.4046 31.3558 12.752 31.4093 13.234C31.4627 13.7159 31.1154 14.15 30.6334 14.2035C30.5012 14.2181 30.3507 14.2363 30.1878 14.2559C29.6624 14.3192 29.0069 14.3982 28.4135 14.4271C28.0214 14.4462 27.6109 14.4465 27.2448 14.3972C26.9218 14.3538 26.4402 14.2499 26.0952 13.9048C25.7501 13.5597 25.6462 13.0782 25.6027 12.7552C25.5535 12.3891 25.5538 11.9786 25.5729 11.5865C25.6017 10.993 25.6807 10.3376 25.7441 9.81215C25.7637 9.64923 25.7818 9.49881 25.7965 9.36662C25.85 8.88464 26.284 8.53726 26.766 8.59072C27.248 8.64418 27.5954 9.07823 27.5419 9.56021C27.5238 9.72372 27.503 9.89589 27.4817 10.0734C27.4297 10.505 27.3739 10.9684 27.3423 11.416L30.5011 8.25717C30.844 7.91427 31.3999 7.91428 31.7428 8.25718Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7551 25.6027C13.0782 25.6462 13.5597 25.7501 13.9048 26.0951C14.2499 26.4402 14.3537 26.9217 14.3972 27.2448C14.4465 27.6109 14.4462 28.0213 14.4271 28.4135C14.3982 29.0069 14.3192 29.6624 14.2559 30.1878C14.2362 30.3507 14.2181 30.5012 14.2034 30.6334C14.15 31.1154 13.7159 31.4627 13.234 31.4093C12.752 31.3558 12.4046 30.9218 12.4581 30.4398C12.4762 30.2763 12.4969 30.1041 12.5183 29.9265C12.5702 29.4949 12.626 29.0316 12.6577 28.584L9.49892 31.7428C9.15603 32.0857 8.60008 32.0857 8.25718 31.7428C7.91428 31.3999 7.91427 30.844 8.25717 30.5011L11.416 27.3423C10.9684 27.3739 10.505 27.4297 10.0734 27.4817C9.89588 27.503 9.72371 27.5238 9.56021 27.5419C9.07823 27.5953 8.64418 27.248 8.59072 26.766C8.53726 26.284 8.88464 25.85 9.36661 25.7965C9.49881 25.7818 9.64922 25.7637 9.81215 25.7441C10.3376 25.6807 10.993 25.6017 11.5865 25.5729C11.9786 25.5538 12.3891 25.5535 12.7551 25.6027Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.584 27.3423C29.0316 27.3739 29.4949 27.4297 29.9266 27.4817C30.1041 27.503 30.2763 27.5238 30.4398 27.5419C30.9218 27.5953 31.3558 27.248 31.4093 26.766C31.4627 26.284 31.1154 25.85 30.6334 25.7965C30.5012 25.7818 30.3508 25.7637 30.1878 25.7441C29.6624 25.6807 29.0069 25.6017 28.4135 25.5729C28.0213 25.5538 27.6109 25.5535 27.2448 25.6027C26.9218 25.6462 26.4402 25.7501 26.0952 26.0951C25.7501 26.4402 25.6462 26.9217 25.6027 27.2448C25.5535 27.6109 25.5538 28.0213 25.5729 28.4135C25.6017 29.0069 25.6807 29.6624 25.7441 30.1878C25.7637 30.3508 25.7818 30.5012 25.7965 30.6334C25.85 31.1154 26.284 31.4627 26.766 31.4093C27.248 31.3558 27.5954 30.9218 27.5419 30.4398C27.5238 30.2763 27.503 30.1041 27.4817 29.9266C27.4297 29.4949 27.3739 29.0316 27.3423 28.584L30.5011 31.7428C30.844 32.0857 31.3999 32.0857 31.7428 31.7428C32.0857 31.3999 32.0857 30.844 31.7428 30.5011L28.584 27.3423Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25717 8.25717C8.60007 7.91428 9.15602 7.91428 9.49892 8.25717L12.6577 11.4159C12.626 10.9684 12.5702 10.5051 12.5183 10.0735C12.4969 9.89592 12.4762 9.72373 12.4581 9.56021C12.4046 9.07823 12.752 8.64418 13.234 8.59072C13.7159 8.53726 14.15 8.88464 14.2034 9.36662C14.2181 9.49882 14.2362 9.64925 14.2559 9.81219C14.3192 10.3376 14.3982 10.9931 14.4271 11.5865C14.4462 11.9786 14.4465 12.3891 14.3972 12.7552C14.3537 13.0782 14.2499 13.5597 13.9048 13.9048C13.5597 14.2499 13.0782 14.3538 12.7551 14.3972C12.389 14.4465 11.9786 14.4462 11.5865 14.4271C10.9931 14.3982 10.3376 14.3192 9.81219 14.2559C9.64925 14.2363 9.49882 14.2181 9.36661 14.2035C8.88464 14.15 8.53726 13.7159 8.59072 13.234C8.64418 12.752 9.07823 12.4046 9.56021 12.4581C9.72373 12.4762 9.89592 12.4969 10.0735 12.5183C10.5051 12.5703 10.9684 12.626 11.4159 12.6577L8.25717 9.49892C7.91428 9.15602 7.91428 8.60007 8.25717 8.25717Z" fill="black" />
                    </svg>
                  </span>
                  Decentralized ownership and access.
                </li>
                <li className="flex items-center gap-2 md:gap-4 text-sm/6 md:text-[22px]/8 text-[#050505]/75">
                  <span className="size-10 flex items-center justify-center *:w-full *:h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.484 18.9199C17.484 18.9199 17.4842 18.9191 17.4849 18.9176L17.484 18.9199ZM17.5051 18.8837C17.5319 18.8466 17.5904 18.7817 17.714 18.6917C17.9768 18.5005 18.4109 18.2935 19.0227 18.1053C20.236 17.7319 21.963 17.4884 23.9071 17.4884C25.8511 17.4884 27.5781 17.7319 28.7914 18.1053C29.4032 18.2935 29.8373 18.5005 30.1001 18.6917C30.2237 18.7817 30.2822 18.8466 30.309 18.8837C30.2822 18.9208 30.2237 18.9858 30.1001 19.0757C29.8373 19.2669 29.4032 19.474 28.7914 19.6622C27.5781 20.0355 25.8511 20.2791 23.9071 20.2791C21.963 20.2791 20.236 20.0355 19.0227 19.6622C18.4109 19.474 17.9768 19.2669 17.714 19.0757C17.5904 18.9858 17.5319 18.9208 17.5051 18.8837ZM17.4849 18.8499C17.4842 18.8483 17.484 18.8475 17.484 18.8475L17.4849 18.8499ZM18.5303 16.5049C19.943 16.0702 21.8438 15.814 23.9071 15.814C25.9703 15.814 27.8711 16.0702 29.2838 16.5049C29.9851 16.7207 30.6136 16.9947 31.0851 17.3377C31.5383 17.6674 32 18.1793 32 18.8837C32 19.5882 31.5383 20.1001 31.0851 20.4298C30.6136 20.7728 29.9851 21.0468 29.2838 21.2626C27.8711 21.6973 25.9703 21.9535 23.9071 21.9535C21.8438 21.9535 19.943 21.6973 18.5303 21.2626C17.829 21.0468 17.2005 20.7728 16.729 20.4298C16.2758 20.1001 15.8141 19.5882 15.8141 18.8837C15.8141 18.1793 16.2758 17.6674 16.729 17.3377C17.2005 16.9947 17.829 16.7207 18.5303 16.5049Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3292 23.8731C30.328 23.8777 30.3256 23.8889 30.3256 23.907H32C32 24.6114 31.5383 25.1233 31.0851 25.453C30.6136 25.796 29.9851 26.0701 29.2839 26.2858C27.8711 26.7205 25.9703 26.9767 23.9071 26.9767C21.8438 26.9767 19.943 26.7205 18.5302 26.2858C17.829 26.0701 17.2005 25.796 16.729 25.453C16.2758 25.1233 15.8141 24.6114 15.8141 23.907H17.4885C17.4885 23.8889 17.4861 23.8777 17.4849 23.8731C17.4907 23.8874 17.531 23.9658 17.714 24.099C17.9768 24.2902 18.4109 24.4972 19.0227 24.6855C20.2359 25.0588 21.963 25.3023 23.9071 25.3023C25.8511 25.3023 27.5782 25.0588 28.7915 24.6855C29.4032 24.4972 29.8373 24.2902 30.1001 24.099C30.2832 23.9658 30.3234 23.8874 30.3292 23.8731ZM30.3301 23.8708C30.3301 23.8708 30.3299 23.8716 30.3292 23.8731L30.3301 23.8708ZM17.484 23.8708C17.4841 23.8708 17.4844 23.8716 17.4849 23.8731L17.484 23.8708Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4885 18.8837V28.707C17.4885 28.707 17.4883 28.7098 17.4906 28.7175C17.4931 28.7258 17.4995 28.7428 17.5152 28.7687C17.548 28.8231 17.6159 28.9062 17.7446 29.0092C18.0069 29.2191 18.4379 29.445 19.0449 29.6505C20.2504 30.0585 21.9694 30.3256 23.9071 30.3256C25.8447 30.3256 27.5637 30.0585 28.7693 29.6505C29.3762 29.445 29.8072 29.2191 30.0695 29.0092C30.1982 28.9062 30.2661 28.8231 30.2989 28.7687C30.3146 28.7428 30.321 28.7258 30.3235 28.7175C30.3258 28.7098 30.3256 28.707 30.3256 28.707V18.8837H32V28.707C32 29.407 31.5796 29.9453 31.1157 30.3166C30.6437 30.6943 30.0121 30.9975 29.3061 31.2365C27.8855 31.7173 25.9767 32 23.9071 32C21.8374 32 19.9286 31.7173 18.508 31.2365C17.802 30.9975 17.1704 30.6943 16.6984 30.3166C16.2345 29.9453 15.8141 29.407 15.8141 28.707V18.8837H17.4885Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.67016 11.106C9.67011 11.106 9.67034 11.1052 9.67097 11.1036L9.67016 11.106ZM9.69125 11.0698C9.718 11.0327 9.77651 10.9677 9.90012 10.8778C10.1629 10.6866 10.597 10.4795 11.2088 10.2913C12.4221 9.91798 14.1491 9.67442 16.0932 9.67442C18.0372 9.67442 19.7643 9.91798 20.9776 10.2913C21.5893 10.4795 22.0234 10.6866 22.2862 10.8778C22.4098 10.9677 22.4683 11.0327 22.4951 11.0698C22.4683 11.1069 22.4098 11.1718 22.2862 11.2618C22.0234 11.4529 21.5893 11.66 20.9776 11.8482C19.7643 12.2216 18.0372 12.4651 16.0932 12.4651C14.1491 12.4651 12.4221 12.2216 11.2088 11.8482C10.597 11.66 10.1629 11.4529 9.90012 11.2618C9.77651 11.1718 9.718 11.1069 9.69125 11.0698ZM9.67098 11.0359C9.67034 11.0344 9.67011 11.0336 9.67016 11.0336L9.67098 11.0359ZM10.7164 8.69092C12.1292 8.25622 14.03 8 16.0932 8C18.1564 8 20.0572 8.25622 21.47 8.69092C22.1712 8.9067 22.7997 9.18072 23.2712 9.52374C23.7244 9.85342 24.1861 10.3653 24.1861 11.0698C24.1861 11.7742 23.7244 12.2861 23.2712 12.6158C22.7997 12.9588 22.1712 13.2328 21.47 13.4486C20.0572 13.8833 18.1564 14.1395 16.0932 14.1395C14.03 14.1395 12.1292 13.8833 10.7164 13.4486C10.0151 13.2328 9.38664 12.9588 8.91511 12.6158C8.46192 12.2861 8.00022 11.7742 8.00022 11.0698C8.00022 10.3653 8.46192 9.85342 8.91511 9.52374C9.38664 9.18072 10.0151 8.9067 10.7164 8.69092Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58593 15.8526C9.02694 15.7137 9.49706 15.9586 9.63596 16.3997C9.73763 16.7225 10.0674 17.0785 10.7779 17.3999C11.4712 17.7135 12.3916 17.9295 13.4036 18.0526C13.8626 18.1085 14.1894 18.5259 14.1336 18.9848C14.0777 19.4438 13.6604 19.7707 13.2014 19.7148C12.1017 19.581 10.9959 19.3362 10.0879 18.9255C9.19707 18.5226 8.35014 17.8909 8.03889 16.9027C7.89999 16.4616 8.14491 15.9915 8.58593 15.8526ZM8.58593 21.434C9.02694 21.2951 9.49706 21.54 9.63596 21.9811C9.73763 22.3039 10.0674 22.6599 10.7779 22.9813C11.4712 23.2949 12.3916 23.5109 13.4036 23.634C13.8626 23.6899 14.1894 24.1073 14.1336 24.5662C14.0777 25.0252 13.6604 25.352 13.2014 25.2962C12.1017 25.1624 10.9959 24.9176 10.0879 24.5069C9.19707 24.104 8.35014 23.4723 8.03889 22.4841C7.89999 22.043 8.14491 21.5729 8.58593 21.434Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83743 10.2326C9.2998 10.2326 9.67463 10.6074 9.67463 11.0698V27.6628C9.80931 27.958 10.1403 28.2743 10.7779 28.5627C11.4712 28.8763 12.3916 29.0923 13.4036 29.2154C13.8626 29.2713 14.1894 29.6886 14.1336 30.1476C14.0777 30.6066 13.6604 30.9334 13.2014 30.8776C12.1017 30.7438 10.9959 30.499 10.0879 30.0883C9.19707 29.6854 8.35014 29.0537 8.03889 28.0655L8.00022 27.9427V11.0698C8.00022 10.6074 8.37505 10.2326 8.83743 10.2326Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3489 10.2326C23.8113 10.2326 24.1861 10.6074 24.1861 11.0698V13.3023C24.1861 13.7647 23.8113 14.1395 23.3489 14.1395C22.8865 14.1395 22.5117 13.7647 22.5117 13.3023V11.0698C22.5117 10.6074 22.8865 10.2326 23.3489 10.2326Z" fill="black" />
                    </svg>
                  </span>
                  Built for growth and monetization.
                </li>
              </ul>
              {/* <div className="mt-6 lg:mt-9">
                <Button
                  className="dark-button relative px-4 py-3 md:px-6 md:py-[14px]"
                  onClick={() => navigate("/")}
                >
                  Know More
                  <span
                    className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                  >
                    {ARROW_ICONS.circleArrow}
                  </span>
                </Button>
              </div> */}
            </div>
          </div>

          <div className="col-span-1 flex items-center justify-center">
            <div className="relative w-[280px] h-[280px] md:w-[320px] md:h-[320px] lg:w-[624px] lg:h-[624px] flex items-center justify-center">
              <div className="absolute top left-0 w-full h-full flex items-center justify-center border-[2px] border-orange rounded-full rotate">
                {/* {[network1, network3, network4, network6, network2].map((icon, index) => {
                  const { left, top } = generateRandomPosition();
                  return (
                    <SingleCircleItem
                      key={index}
                      left={left}
                      top={top}
                      icon={icon}
                      className="bg-black opacity-100 w-[40px] h-[40px] laptop:w-[80px] laptop:h-[80px]"
                    />
                  );
                })} */}
                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[20%] left-[10%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}1.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[80%] left-[10%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}2.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[20%] left-[90%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}3.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[80%] left-[90%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}4.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] -top-[8%] lg:-top-[7%] left-[50%]`}
                  style={{
                    transform: `translateX(-50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}5.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="absolute w-[65%] h-[65%] flex items-center justify-center border-[2px] border-orange rounded-full antirotation">
                {/* {[network2, network5, network1].map((icon, index) => {
                    const { left, top } = generateRandomPosition();
                    return (
                      <SingleCircleItem
                        key={index}
                        left={left}
                        top={top}
                        icon={icon}
                        className="bg-black opacity-100 w-[40px] h-[40px] laptop:w-[80px] laptop:h-[80px]"
                      />
                    );
                  })} */}

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[50%] left-0`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}6.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[50%] left-[100%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}7.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] -top-[10%] left-[50%]`}
                  style={{
                    transform: `translateX(-50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}8.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="absolute max-w-[30%] lg:max-w-[60%] *:w-full *:h-full flex items-center justify-center *:relative *:top-2">
                {INFECTIVlOGOS.inflectivShadow}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default DecentratizedFuture;
